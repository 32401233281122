import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { DatePicker, Form, Input, Radio, Space } from 'antd';
import { Kontrollergebnis, SprinklerInspection, SprinklerKontrolltypen } from '../../../models';
import { gvsgDateFormat } from '../../../common/utils';

type props = {
    inspection?: SprinklerInspection;
    disabled?: boolean;
};

export const MaengelFormInputs = ({ inspection, disabled }: props) => {
    const [selectedKontrollergebnis, setKontrollergebnis] = useState<Kontrollergebnis>();
    useEffect(() => {
        setKontrollergebnis(inspection?.kontrollergebnis);
    }, [inspection?.id]);
    const hasMaengel = () =>
        selectedKontrollergebnis === Kontrollergebnis.bedingt ||
        selectedKontrollergebnis === Kontrollergebnis.mangelhaft;

    const { kontrollergebnis, maengel, bemerkungen, nachkontrolleErforderlich, fristbehebung, kontrolltyp } =
        inspection || {};

    const isNachkontrolle = kontrolltyp === SprinklerKontrolltypen.nachkontrolle;

    return (
        <>
            <Form.Item
                initialValue={kontrollergebnis}
                rules={[{ required: !disabled, message: 'Eingabe erforderlich' }]}
                name="kontrollergebnis"
                label="Kontrollergebnis"
            >
                <Radio.Group onChange={(e) => setKontrollergebnis(e.target.value)} disabled={disabled}>
                    <Space direction="vertical">
                        <Radio value={Kontrollergebnis.maengelfrei}>Die SPA ist funktionstüchtig und mängelfrei</Radio>
                        <Radio value={Kontrollergebnis.einschraenkungen}>
                            Die SPA ist mit Einschränkungen funktionstüchtig (s. Bemerkungen)
                        </Radio>
                        <Radio value={Kontrollergebnis.bedingt}>
                            Die SPA ist bedingt funktionstüchtig und muss ergänzt werden (s. Mängel)
                        </Radio>
                        <Radio value={Kontrollergebnis.mangelhaft}>
                            Die SPA ist mangelhaft und muss gemäss SES-Richtlinien ergänzt werden (s. Mängel)
                        </Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>

            <Form.Item
                initialValue={maengel}
                rules={[{ required: hasMaengel() && !disabled, message: 'Eingabe erforderlich' }]}
                name="maengel"
                label="Mängel"
            >
                <Input.TextArea disabled={disabled || !hasMaengel()} rows={4} />
            </Form.Item>

            {!isNachkontrolle && (
                <Form.Item
                    initialValue={fristbehebung && moment(fristbehebung)}
                    rules={[{ required: hasMaengel() && !disabled, message: 'Eingabe erforderlich' }]}
                    name="fristbehebung"
                    label="Frist zur Behebung der Mängel"
                >
                    <DatePicker
                        disabledDate={(current) => current && current < moment().endOf('day')}
                        disabled={disabled || !hasMaengel()}
                        format={gvsgDateFormat}
                    />
                </Form.Item>
            )}

            {!isNachkontrolle && (
                <Form.Item
                    initialValue={nachkontrolleErforderlich}
                    rules={[{ required: hasMaengel() && !disabled, message: 'Eingabe erforderlich' }]}
                    name="nachkontrolleErforderlich"
                    label="Nachkontrolle nötig"
                >
                    <Radio.Group disabled={disabled || !hasMaengel()}>
                        <Radio value={true}>Ja</Radio>
                        <Radio value={false}>Nein</Radio>
                    </Radio.Group>
                </Form.Item>
            )}

            <Form.Item
                initialValue={bemerkungen}
                rules={[
                    {
                        required: selectedKontrollergebnis === Kontrollergebnis.einschraenkungen,
                        message: 'Eingabe erforderlich',
                    },
                ]}
                name="bemerkungen"
                label="Spezielles / Bemerkungen"
            >
                <Input.TextArea rows={4} disabled={disabled} />
            </Form.Item>
        </>
    );
};
