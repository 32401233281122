import { Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { SprinklerInspection } from '../../../models';

type props = {
    inspection?: SprinklerInspection;
    label?: string;
};

export const MapButton = ({ inspection, label }: props) => {
    let link = 'https://www.geoportal.ch/ch/map/40?scale=500';

    if (inspection?.assuranceNumber) {
        link += `&topic=assuranceNo&bfsnr=${inspection?.bfsNumber}&assuranceNo=${inspection?.geoportalGebaeudeNumber}`;
    } else {
        link += `&topic=address&plz=${inspection?.bfsNumber}&street=${inspection?.street}`;

        if (inspection?.houseNumber) link += `&houseno=${inspection?.houseNumber}`;
    }

    //link += '&format=application/pdf&paperSize=A4&resolution=100&grid=true&orientation=Landscape';

    return (
        <>
            <a href={link} target="_blank">
                {label}
            </a>
        </>
    );
};
