import moment from 'moment';
import rawaxios from 'axios';
import { ApiInstance } from './apiInstance';
import { RcFile } from 'antd/lib/upload';
import { Document } from '../models';
import { DocumentSource } from '../models';

export const getDocuments = async (inspectionId: string) => {
    const { data: documents } = await ApiInstance.get<Document[]>(`sprinklerInspections/${inspectionId}/documents`);
    return documents;
};

export const uploadDocument = async (
    sprinklerInspectionId: string,
    assuranceNumber: string,
    file: RcFile
): Promise<Document | null> => {
    const doc = await createDocument(
        {
            name: file.name,
            sprinklerInspection: sprinklerInspectionId,
            documentSource: DocumentSource.InspectionUpload,
        },
        assuranceNumber
    );

    if (doc && doc.uri) {
        const formData = new FormData();
        formData.append('files[]', file);
        const response = await uploadToBlobStorage(doc.uri, file);

        if (response.status !== 201) {
            await ApiInstance.delete(`/documents/${doc.id}`);
            throw new Error('Dokumentupload ist fehlgeschlagen');
        }

        return doc;
    }

    return null;
};

async function createDocument(document: Document, assuranceNumber: string): Promise<Document | null> {
    const result = await ApiInstance.post<Document>(`/documents?assuranceNumber=${assuranceNumber}`, document);
    if (!result || !result.data) return null;

    if (result.status !== 200) {
        throw new Error('Dokumenterstellung fehlgeschlagen: ' + result.statusText);
    }

    return result.data;
}

async function uploadToBlobStorage(signedUrl: string, file: File): Promise<any> {
    const date = moment().toISOString();
    const result = await rawaxios.put(signedUrl, file, {
        headers: {
            'x-ms-blob-type': 'BlockBlob',
            'x-ms-date': date,
            'Cache-Control': 'no-cache',
            'Content-Type': file.type,
        },
    });
    return result;
}
