import { User } from '../models';

export const isLoggedIn = (): boolean => {
    const { token } = getCurrentUser();

    return !!token;
};

export const getCurrentUser = (): User => {
    const userString = localStorage.getItem('currentUser');
    if (!userString) return {};

    return JSON.parse(userString);
};

export const setCurrentUser = (user: User) => {
    if (!user?.token) throw new Error('Invalid User Token');
    localStorage.setItem('currentUser', JSON.stringify(user));
};
