import { Form, Radio, Input } from 'antd';
import React, { useState } from 'react';
import { Pflichtig, SprinklerInspection } from '../../../models';
import { oneColumnLayout } from './GridColumnSizes';

type props = {
    inspection?: SprinklerInspection;
    disabled?: boolean;
};

export const PflichtigInputs = ({ inspection, disabled }: props) => {
    const [selectedPflichtig, setSelectedPflichtig] = useState<Pflichtig>();

    const { pflichtig, pflichtigBemerkungen } = inspection || {};

    return (
        <>
            <Form.Item
                initialValue={pflichtig || Pflichtig.pflichtig}
                rules={[{ required: true, message: 'Eingabe erforderlich' }]}
                {...oneColumnLayout}
                name="pflichtig"
            >
                <Radio.Group onChange={(e) => setSelectedPflichtig(e.target.value)} disabled={disabled}>
                    <Radio value={Pflichtig.freiwilig}>Freiwillige Anlage</Radio>
                    <Radio value={Pflichtig.pflichtig}>Pflichtige Anlage</Radio>
                    <Radio value={Pflichtig.unsicher}>unsicher</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item
                initialValue={pflichtigBemerkungen}
                rules={[{ required: selectedPflichtig === Pflichtig.unsicher, message: 'Eingabe erforderlich' }]}
                name="pflichtigBemerkungen"
                label="Begründung"
            >
                <Input.TextArea rows={3} disabled={disabled} />
            </Form.Item>
        </>
    );
};
