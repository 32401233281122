import { Skeleton } from 'antd';
import React from 'react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApiInstance } from '../../../api/apiInstance';
import { getDocuments } from '../../../api/documentService';
import { SprinklerInspection, Document, SprinklerInspectionStatus } from '../../../models';
import { EditInspection } from './EditInspection';
import { ReadOnlyInspection } from './ReadOnlyInspection';

export const FormSelector = () => {
    let params = useParams();
    const { inspectionId } = params;

    const [sprinklerInspection, setSprinklerInspection] = useState<SprinklerInspection>();
    const [documentList, setDocumentList] = useState<Document[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const fetchData = async () => {
        const { data: inspectionData } = await ApiInstance.get<SprinklerInspection>(
            `sprinklerInspections/${inspectionId}`
        );

        const documents = await getDocuments(inspectionId!);

        setDocumentList(documents);
        setSprinklerInspection(inspectionData);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inspectionId]);

    const { status } = sprinklerInspection || {};
    const isReadOnlyState =
        status === SprinklerInspectionStatus.cancelled || status === SprinklerInspectionStatus.completed;
    return (
        <>
            {isLoading ? (
                <Skeleton active />
            ) : isReadOnlyState ? (
                <ReadOnlyInspection documentList={documentList} sprinklerInspection={sprinklerInspection!} />
            ) : (
                <EditInspection
                    setDocument={setDocumentList}
                    documentList={documentList}
                    updatePrinklerInspection={setSprinklerInspection}
                    sprinklerInspection={sprinklerInspection!}
                />
            )}
        </>
    );
};
