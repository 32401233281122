import React, { useEffect, useState } from 'react';
import { Menu, Layout } from 'antd';
import Icon, { LogoutOutlined } from '@ant-design/icons';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Private } from './pages/login/private';
import { ReactComponent as HardHatLogo } from './assets/hard-hat-svgrepo-com.svg';
import logo from './assets/gvsg_logo.svg';

const { Content } = Layout;

export const AppLayout = () => {
    let navigate = useNavigate();
    let location = useLocation();
    const [selectedMenu, setSelectedMenu] = useState('/');

    useEffect(() => {
        if (location?.pathname === '/') {
            navigate('/inspections');
        }
    }, [location?.pathname, navigate]);

    const handleClick = (e: any) => {
        setSelectedMenu(e.key);
        switch (e.key) {
            case 'logo':
                navigate('/inspections');
                break;
            case 'logout':
                localStorage.removeItem('currentUser');
                navigate('/');
                break;
            default:
                navigate(e.key);
                break;
        }
    };

    return (
        <>
            <Private>
                <Layout style={{ height: '100vh', backgroundColor: 'white' }}>
                    <div>
                        <Menu onClick={handleClick} selectedKeys={[selectedMenu]} mode="horizontal">
                            <Menu.Item style={{ border: '0px solid' }} key="logo">
                                <img style={{ width: '119px', height: '60px' }} src={logo} alt="GVSG" />
                            </Menu.Item>
                            <Menu.Item
                                key="inspections"
                                icon={<Icon component={HardHatLogo} style={{ fontSize: '24px' }} />}
                            >
                                Löschanlagenkontrollen
                            </Menu.Item>
                            <Menu.Item key="logout" style={{ float: 'right' }} icon={<LogoutOutlined />}>
                                <span style={{ marginRight: 15 }}>Logout</span>
                            </Menu.Item>
                        </Menu>
                    </div>
                    {/* DO NOT ALTER PADDING AND MARGIN HERE -> IT AFFECTS ALL SCREENS */}
                    <Content style={{ marginTop: '0px', padding: '20px' }}>
                        <Outlet />
                    </Content>
                </Layout>
            </Private>
        </>
    );
};
