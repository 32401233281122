import { Descriptions, Input, InputNumber } from 'antd';
import moment from 'moment';
import React from 'react';
import {
    SprinklerInspection,
    Document,
    AnlageArtDisplay,
    PflichtigDisplay,
    KontrollergebnisDisplay,
    SchutzTyp,
    SprinklerKontrolltypenDisplay,
    SprinklerKontrolltypen,
} from '../../../models';
import { DocumentList } from './DocumentList';

type props = {
    documentList: Document[];
    sprinklerInspection: SprinklerInspection;
};

export const ReadOnlyInspection = ({ sprinklerInspection, documentList }: props) => {
    const {
        assuranceNumber,
        anlageart,
        bewilligungsNr,
        errichterfirmaLoeschanlage,
        gebaudeAdresse,
        gebaeudeteile,
        interneBemerkungen,
        fristbehebung,
        nachkontrolleErforderlich,
        maengel,
        pflichtig,
        pflichtigBemerkungen,
        kontrollergebnis,
        verwalter,
        schutzTyp,
        schutzBemerkungen,
        bemerkungen,
        zusaetzlicheVerteiler,
        zeitaufwand,
        nutzungsart,
        grundbuchkreis,
        kontrolldatum,
        kontrolltyp,
        inUmbau,
        keineAnlage,
        letzteKontrolle,
        letzteRevision,
        isPeriodischeKontrolleProzess,
        subventionsNr,
    } = sprinklerInspection || {};

    const kontrollTypDisplay = SprinklerKontrolltypenDisplay.filter((x) => x.key === kontrolltyp)[0]?.displayValue;
    const isNachkontrolle = kontrolltyp === SprinklerKontrolltypen.nachkontrolle;
    const isKontrolltypPeriodischeKontrolle = kontrolltyp === SprinklerKontrolltypen.periodischeKontrolle;
    const isErstAbnahme = () => kontrolltyp === SprinklerKontrolltypen.erstabnahme;

    const isAbnahme =
        kontrolltyp === SprinklerKontrolltypen.erstabnahme ||
        kontrolltyp === SprinklerKontrolltypen.teilabnahme ||
        kontrolltyp == SprinklerKontrolltypen.generalrevision;

    return (
        <div>
            <h1>Kontrollrapport {kontrollTypDisplay}</h1>
            <Descriptions size="small" bordered labelStyle={{ width: 400 }}>
                {!isAbnahme && (
                    <Descriptions.Item label="Nutzungsart" span={4}>
                        {nutzungsart}
                    </Descriptions.Item>
                )}

                <Descriptions.Item label="Versicherungsnummer" span={4}>
                    {assuranceNumber}
                </Descriptions.Item>
                <Descriptions.Item label="Verwalter" span={4}>
                    {verwalter}
                </Descriptions.Item>
                <Descriptions.Item label="Standortadresse Gebäude" span={4}>
                    {gebaudeAdresse}
                </Descriptions.Item>

                {!isAbnahme && (
                    <Descriptions.Item label="Gemeinde" span={4}>
                        {grundbuchkreis}
                    </Descriptions.Item>
                )}

                {isKontrolltypPeriodischeKontrolle && (
                    <Descriptions.Item label="Objekt in Umbau" span={4}>
                        {inUmbau ? 'Ja' : 'Nein'}
                    </Descriptions.Item>
                )}

                {!isPeriodischeKontrolleProzess && !isErstAbnahme() && (
                    <Descriptions.Item label="geschützte Gebäudebereiche" span={4}>
                        {gebaeudeteile}
                    </Descriptions.Item>
                )}

                <Descriptions.Item span={4}>{undefined} </Descriptions.Item>
                <Descriptions.Item label="Datum der Kontrolle" span={4}>
                    {kontrolldatum && moment(kontrolldatum).format('DD.MM.YYYY')}
                </Descriptions.Item>
                <Descriptions.Item label="Zeitaufwand" span={4}>
                    {zeitaufwand && (
                        <InputNumber
                            style={{ marginLeft: -10 }}
                            value={zeitaufwand}
                            readOnly
                            decimalSeparator="."
                            min={0}
                            max={100}
                            precision={2}
                            bordered={false}
                        />
                    )}
                </Descriptions.Item>

                {isPeriodischeKontrolleProzess && (
                    <Descriptions.Item label="Letzte Kontrolle" span={4}>
                        {letzteKontrolle && moment(letzteKontrolle).format('DD.MM.YYYY')}
                    </Descriptions.Item>
                )}

                {isPeriodischeKontrolleProzess && (
                    <Descriptions.Item label="Letzte Revision durch Sprinklerfirma" span={4}>
                        {letzteRevision && moment(letzteRevision).format('DD.MM.YYYY')}
                    </Descriptions.Item>
                )}

                {!isPeriodischeKontrolleProzess && (
                    <Descriptions.Item label="Errichterfirma der Löschanlage" span={4}>
                        {errichterfirmaLoeschanlage}
                    </Descriptions.Item>
                )}

                {!isPeriodischeKontrolleProzess && (
                    <Descriptions.Item label="Anlage-Art" span={4}>
                        {AnlageArtDisplay.filter((a) => a.key === anlageart)[0]?.displayValue}
                    </Descriptions.Item>
                )}

                <Descriptions.Item span={4}>{undefined} </Descriptions.Item>

                <Descriptions.Item label="Schutzumfang" span={4}>
                    {schutzTyp === SchutzTyp.vollschutz ? 'Vollschutz' : 'Teilschutz'}
                </Descriptions.Item>
                <Descriptions.Item label="geschützte Gebäudebereiche" span={4}>
                    {schutzBemerkungen && (
                        <Input.TextArea
                            style={{ marginLeft: -10 }}
                            value={schutzBemerkungen}
                            autoSize={{ minRows: schutzBemerkungen?.indexOf('\n') }}
                            readOnly
                            bordered={false}
                        />
                    )}
                </Descriptions.Item>

                <Descriptions.Item span={4}>{undefined} </Descriptions.Item>

                {!isNachkontrolle && (
                    <Descriptions.Item label="Pflichtig" span={4}>
                        {PflichtigDisplay.filter((p) => p.key === pflichtig)[0]?.displayValue}
                    </Descriptions.Item>
                )}

                {!isNachkontrolle && (
                    <Descriptions.Item label="Begründung" span={4}>
                        {pflichtigBemerkungen && (
                            <Input.TextArea
                                style={{ marginLeft: -10 }}
                                value={pflichtigBemerkungen}
                                autoSize={{ minRows: pflichtigBemerkungen?.indexOf('\n') }}
                                readOnly
                                bordered={false}
                            />
                        )}
                    </Descriptions.Item>
                )}

                {isAbnahme && (
                    <Descriptions.Item label="Bewilligungs-Nr." span={4}>
                        {bewilligungsNr}
                    </Descriptions.Item>
                )}

                {isAbnahme && (
                    <Descriptions.Item label="Subventions-Nr." span={4}>
                        {subventionsNr}
                    </Descriptions.Item>
                )}

                <Descriptions.Item label="Kontrollergebnis" span={3}>
                    {KontrollergebnisDisplay.filter((k) => k.key === kontrollergebnis)[0].displayValue}
                </Descriptions.Item>

                <Descriptions.Item label="Mängel" span={4}>
                    {maengel && (
                        <Input.TextArea
                            style={{ marginLeft: -10 }}
                            value={maengel}
                            autoSize={{ minRows: maengel?.indexOf('\n') }}
                            readOnly
                            bordered={false}
                        />
                    )}
                </Descriptions.Item>

                {!isNachkontrolle && (
                    <Descriptions.Item label="Frist zur Behebung der Mängel" span={4}>
                        {fristbehebung && moment(fristbehebung).format('DD.MM.YYYY')}
                    </Descriptions.Item>
                )}

                {!isNachkontrolle && (
                    <Descriptions.Item label="Nachkontrolle nötig" span={4}>
                        {nachkontrolleErforderlich ? 'ja' : 'nein'}
                    </Descriptions.Item>
                )}

                <Descriptions.Item label="Spezielles / Bemerkungen" span={4}>
                    {bemerkungen && (
                        <Input.TextArea
                            style={{ marginLeft: -10 }}
                            value={bemerkungen}
                            autoSize={{ minRows: bemerkungen?.indexOf('\n') }}
                            readOnly
                            bordered={false}
                        />
                    )}
                </Descriptions.Item>
                <Descriptions.Item label="Allgemeine Bemerkungen zum Objekt (INTERN)" span={4}>
                    {interneBemerkungen && (
                        <Input.TextArea
                            style={{ marginLeft: -10 }}
                            value={interneBemerkungen}
                            autoSize={{ minRows: interneBemerkungen?.indexOf('\n') }}
                            readOnly
                            bordered={false}
                        />
                    )}
                </Descriptions.Item>
                <Descriptions.Item label="zus. Verteiler (System)" span={4}>
                    {zusaetzlicheVerteiler && (
                        <Input.TextArea
                            style={{ marginLeft: -10 }}
                            value={zusaetzlicheVerteiler}
                            autoSize={{ minRows: zusaetzlicheVerteiler?.indexOf('\n') }}
                            readOnly
                            bordered={false}
                        />
                    )}
                </Descriptions.Item>

                <Descriptions.Item label="Dokumente" span={4}>
                    <DocumentList documents={documentList} />
                </Descriptions.Item>
            </Descriptions>
        </div>
    );
};
