import * as React from 'react';
import { useEffect } from 'react';
import { Row, Col, Button } from 'antd';
import { ApiInstance } from '../../api/apiInstance';
import { SprinklerInspection } from '../../models';
import { getCurrentUser } from '../../common/token';

export async function getCancelledInspections(inspectorId: string): Promise<SprinklerInspection[]> {
    const result = await ApiInstance.get<SprinklerInspection[]>(`/sprinklerinspections/cancelled/${inspectorId}`);
    if (!result || !result.data) return [];

    return result.data;
}

export async function putAcceptCancellation(inspectorId: string): Promise<SprinklerInspection[]> {
    const result = await ApiInstance.put<SprinklerInspection[]>(
        `/sprinklerinspections/acceptCancellations/${inspectorId}`
    );
    if (!result || !result.data) return [];

    return result.data;
}

export const CanceledInspections = () => {
    const [cancelledInspections, setCancelledInspection] = React.useState<SprinklerInspection[]>([]);

    const inspectorId = getCurrentUser().id;

    useEffect(() => {
        if (!inspectorId) return;
        getCancelledInspections(inspectorId).then((i) => {
            setCancelledInspection(i);
        });
    }, [inspectorId]);

    const columns = cancelledInspections.map((i) => {
        return (
            <Row key={i.assuranceNumber}>
                <Col span={6}>{i.assuranceNumber}</Col>
                <Col span={6}>{i.nutzungsart}</Col>
                <Col span={6}>{i.gebaudeAdresse}</Col>
                <Col span={6}>{i.grundbuchkreis}</Col>
            </Row>
        );
    });

    const acceptCancellations = async () => {
        if (!inspectorId) return;
        await putAcceptCancellation(inspectorId);
        window.location.reload();
    };

    return cancelledInspections.length > 0 ? (
        <Row style={{ padding: '20px', border: '2px solid orange' }}>
            <Col>
                <Row>
                    <Col>
                        <p style={{ fontWeight: 'bold' }}>
                            Bitte nehmen Sie zur Kenntnis, dass folgende Aufträge zurückgezogen wurden:
                        </p>
                    </Col>
                </Row>
                {columns}
                <Row style={{ marginTop: '20px' }}>
                    <Col offset={0}>
                        <Button onClick={acceptCancellations} type="primary">
                            Zur Kenntnis nehmen
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    ) : null;
};
