const config = {
    default: {
        api: process.env.REACT_APP_BACKEND_API || 'https://localhost:44333',
    },
    dev: {
        api: 'https://app-valeria-api-dev.azurewebsites.net',
    },
    uat: {
        api: 'https://app-valeria-api-uat.azurewebsites.net',
    },
    prod: {
        api: 'https://valeria-api.gvsg.ch',
    },
    gvsg: {
        api: 'https://valeria-api.gvsg.ch',
    },
};
type ConfigKeysType = keyof typeof config;

export const getEnvironment = () => {
    const { hostname } = window.location;
    const configIdentifier = Object.keys(config).find((key) => hostname.includes(key));
    return configIdentifier ? config[configIdentifier as ConfigKeysType] : config.default;
};
