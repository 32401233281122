import React from 'react';
import ReactDOM from 'react-dom';
import { AppRouter } from './AppRouter';

import deDe from 'antd/lib/locale/de_DE';
import moment from 'moment';
import 'moment/locale/de-ch';

import 'antd/dist/antd.css';
import { ConfigProvider } from 'antd';

moment.locale('de');

ReactDOM.render(
    <ConfigProvider locale={deDe}>
        <AppRouter />
    </ConfigProvider>,
    document.getElementById('root')
);
