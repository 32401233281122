import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, message, Popconfirm } from 'antd';
import React from 'react';
import { ApiInstance } from '../../../api/apiInstance';
import { Document } from '../../../models';

type props = {
    documents: Document[];
    onDelete?: (document: Document) => void;
};

export const DocumentList = ({ documents, onDelete }: props) => {
    const hasDocuments = documents.length > 0;
    return (
        <>
            {hasDocuments ? (
                <div>
                    {documents.map((document) => {
                        return <DocumentDisplay onDelete={onDelete} key={document.id} document={document} />;
                    })}
                </div>
            ) : (
                <div>
                    <i>Keine Anhänge</i>
                </div>
            )}
        </>
    );
};

type propsDocumentDisplay = {
    document: Document;
    onDelete?: (document: Document) => void;
};
export const DocumentDisplay = ({ document, onDelete }: propsDocumentDisplay) => {
    const confirm = async (e: any) => {
        try {
            if (!onDelete) return;

            await ApiInstance.delete(`documents/${document.id}`);
            onDelete(document);
            message.success('Document erfolgreich gelöscht');
        } catch (e) {
            message.error('Fehler bei Löschen vom Dokument');
            console.error(e);
        }
    };

    return (
        <div>
            <a target="_blank" rel="noreferrer" href={document.uri}>
                {document.name}
            </a>
            <Popconfirm
                title="Wollen Sie den Anhang wirklich löschen?"
                onConfirm={confirm}
                okText="Ja"
                cancelText="Nein"
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            >
                {onDelete && <Button type="link" danger shape="circle" icon={<DeleteOutlined />} />}
            </Popconfirm>
        </div>
    );
};
