import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import React from 'react';

type props = {
    setFiles: (files: RcFile[]) => void;
    files: RcFile[];
};

export const UploadDocumentComponent = ({ setFiles, files }: props) => {
    return (
        <Upload
            beforeUpload={(file: RcFile, filelist: RcFile[]) => {
                setFiles(filelist);

                return false;
            }}
            onRemove={(file) => {
                setFiles(files.filter((item) => item !== file));
            }}
            fileList={files}
            name="anhang"
            action=""
            multiple={true}
        >
            <Button icon={<UploadOutlined />}>Anhang auswählen</Button>
        </Upload>
    );
};
