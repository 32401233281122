import { Button, Col, Row } from 'antd';
import 'devextreme/data/odata/store';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import {
    Column,
    DataGrid,
    FilterRow,
    HeaderFilter,
    GroupPanel,
    Scrolling,
    Grouping,
    StateStoring,
    Sorting,
    RemoteOperations,
} from 'devextreme-react/data-grid';
import { getEnvironment } from '../config/enviornment';
import { getCurrentUser } from '../common/token';
import {
    InspectionStatusDisplay,
    KontrolleurTyp,
    SprinklerInspection,
    SprinklerInspectionStatus,
    SprinklerKontrolltypenDisplay,
} from '../models';
import React, { useRef } from 'react';
import { loadMessages, locale } from 'devextreme/localization';
import deMessages from 'devextreme/localization/messages/de.json';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { useNavigate } from 'react-router-dom';
import { CanceledInspections } from './cancelledInspections/CanceledInspections';
import moment from 'moment';

export const Inspections = () => {
    const navigate = useNavigate();
    loadMessages(deMessages);
    locale('de-CH');
    const grid = useRef<DataGrid>();

    const expandebleStates: SprinklerInspectionStatus[] = [
        SprinklerInspectionStatus.open,
        SprinklerInspectionStatus.started,
    ];

    const dataSource: any = createStore({
        key: 'id',
        onBeforeSend: (method, param) => {
            const { token } = getCurrentUser();
            const bearerToken = `Bearer ${token!}`;
            param.headers = {
                Authorization: bearerToken,
            };
            const { api } = getEnvironment();
            param.url = `${api}/v1/inspectors/${getCurrentUser().id}/sprinklerinspections/dxdataSource`;
        },
    });

    const resetLayout = () => {
        localStorage.removeItem('dxGridStorage');
        document.location.reload();
    };

    const onInspectionSelected = (selectedItems: any) => {
        const selectedItem = selectedItems.selectedRowsData[0] as SprinklerInspection;
        if (!selectedItem) return;
        const { id } = selectedItem;
        navigate(`${id}`);
    };

    const isDeadlineExpired = (inspectionDeadline: Date): boolean => {
        const deadline = moment(inspectionDeadline);
        return moment().isAfter(deadline);
    };

    const expandRows = (gridComponent: any) => {
        const expandGroupChildren = async (component: any, parentKey: number) => {
            const row = await component.byKey([parentKey]);
            if (!row || !row.collapsedItems) return;

            row.collapsedItems.forEach((item: { key: any }) => {
                component.expandRow([parentKey, item.key]);
            });
        };
        if (!gridComponent.___avaInitiallyExpanded) {
            gridComponent.___avaInitiallyExpanded = true;

            const statusColumnIndex = gridComponent.getVisibleColumnIndex('inspectionStatus');
            if (statusColumnIndex !== 0) return;

            expandebleStates.forEach((s) => {
                void expandGroupChildren(gridComponent, s);
                gridComponent.expandRow([s]);
            });
        }
    };

    return (
        <>
            <h1>Löschanlagenkontrollen</h1>
            <div style={{ marginTop: '0px', padding: '15px' }}>
                <Row style={{ marginTop: '20px' }}>
                    <Col offset={1} span={18}>
                        <CanceledInspections />
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    {getCurrentUser().inspectorType === KontrolleurTyp.Aufseher && (
                        <Col>
                            <Button onClick={() => navigate('/newInspection')} type="primary">
                                Abnahmekontrolle erfassen
                            </Button>
                        </Col>
                    )}
                    <Col>
                        <Button style={{ marginLeft: '10px' }} onClick={resetLayout}>
                            Ansicht zurücksetzen
                        </Button>
                    </Col>
                </Row>

                <Row style={{ marginTop: '20px' }}>
                    <Col>
                        <DataGrid
                            // @ts-ignore
                            ref={grid}
                            dataSource={dataSource}
                            selection={{ mode: 'single' }}
                            onSelectionChanged={onInspectionSelected}
                            selectedRowKeys={[]}
                            columnMinWidth={150}
                            hoverStateEnabled
                            showBorders
                            allowColumnResizing
                            allowColumnReordering
                            remoteOperations
                            onRowPrepared={(e: any) => {
                                if (
                                    e.rowType === 'data' &&
                                    e.data.status !== SprinklerInspectionStatus.completed &&
                                    isDeadlineExpired(e.data.fristKontrolle)
                                ) {
                                    e.rowElement.style.color = 'red';
                                    e.rowElement.className = e.rowElement.className.replace('dx-row-alt', '');
                                }
                            }}
                            onContentReady={(e: any) => {
                                expandRows(e.component);
                            }}
                        >
                            <FilterRow visible />
                            <HeaderFilter visible />
                            <GroupPanel visible />
                            <Scrolling mode="virtual" showScrollbar="always" />
                            <Sorting mode="multiple" />
                            <Grouping autoExpandAll={false} />
                            <RemoteOperations grouping sorting />
                            <StateStoring enabled type="localStorage" storageKey="dxGridStorage" />
                            <Column caption="Versicherungsnummer" dataField="assuranceNumber" allowSorting width={90} />
                            <Column caption="Nutzung" dataField="nutzungsart" allowSorting />
                            <Column caption="Adresse" dataField="gebaudeAdresse" allowSorting />
                            <Column caption="Grundbuchkreis" dataField="grundbuchkreis" allowSorting />
                            <Column
                                caption="Kontrolltyp"
                                dataField="kontrolltyp"
                                allowSorting
                                lookup={{
                                    dataSource: SprinklerKontrolltypenDisplay,
                                    valueExpr: 'key',
                                    displayExpr: 'displayValue',
                                }}
                            />
                            <Column
                                caption="Frist"
                                dataField="fristKontrolle"
                                dataType="date"
                                format="shortDate"
                                allowSorting
                                minWidth={100}
                            />
                            <Column
                                groupIndex={0}
                                caption="Status"
                                dataField="inspectionStatus"
                                allowSorting
                                lookup={{
                                    dataSource: InspectionStatusDisplay,
                                    valueExpr: 'key',
                                    displayExpr: 'displayValue',
                                }}
                            />
                        </DataGrid>
                    </Col>
                </Row>
            </div>
        </>
    );
};
