import React, { useState } from 'react';
import moment from 'moment';
import { Button, Divider, Form, Input, Radio, DatePicker, Row, Col, InputNumber, message } from 'antd';
import {
    Document,
    DocumentSource,
    SchutzTyp,
    SprinklerInspection,
    SprinklerInspectionStatus,
    SprinklerKontrolltypen,
    SprinklerKontrolltypenDisplay,
} from '../../../models';
import { gvsgDateFormat } from '../../../common/utils';
import { ApiInstance } from '../../../api/apiInstance';
import { AnlageArtInput } from './AnlageArtInput';
import { getCurrentUser } from '../../../common/token';
import { DocumentList } from './DocumentList';
import { oneColumnLayout, twoColumnLayout } from '../common/GridColumnSizes';
import { MaengelFormInputs } from '../common/MaengelFormInputs';
import { uploadDocument } from '../../../api/documentService';
import { PflichtigInputs } from '../common/PflichtigInputs';
import { UploadDocumentComponent } from '../common/UploadDocumentComponent';
import { RcFile } from 'antd/lib/upload';
import { MapButton } from '../common/MapButton';
import { useNavigate } from 'react-router-dom';

type props = {
    documentList: Document[];
    sprinklerInspection: SprinklerInspection;
    setDocument: (documentList: Document[]) => void;
    updatePrinklerInspection: (inspection: SprinklerInspection) => void;
};

export const EditInspection = ({
    documentList,
    sprinklerInspection,
    setDocument,
    updatePrinklerInspection: updateSprinklerInspection,
}: props) => {
    let navigate = useNavigate();

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [closeAfterSubmit, setCloseAfterSubmit] = useState<boolean>(true);
    const [currentInUmbau, setCurrentInUmbau] = useState<boolean>(false);
    const [currentKeineAnlage, setCurrentKeineAnlage] = useState<boolean>(false);

    const [inspectionStateAfterSubmit, setInspectionStateAfterSubmit] = useState<SprinklerInspectionStatus>(
        SprinklerInspectionStatus.started
    );
    const [files, setFiles] = useState<RcFile[]>();

    const { id: inspectionId } = sprinklerInspection;

    const [form] = Form.useForm();

    const onSubmit = async (values: any) => {
        try {
            console.log('values', values);

            setIsSaving(true);

            const { id: inspectorId } = getCurrentUser();
            const modelToUpdate: SprinklerInspection = {
                ...sprinklerInspection,
                ...values,
                inspectorId,
                status: inspectionStateAfterSubmit,
            };
            const { data: updatedSprinklerInspection } = await ApiInstance.put<SprinklerInspection>(
                `sprinklerInspections/${inspectionId}`,
                modelToUpdate
            );

            if (files && updatedSprinklerInspection && updatedSprinklerInspection.assuranceNumber) {
                const { id: sprinklerInspectionId, assuranceNumber } = updatedSprinklerInspection;

                const newDocumentList: Document[] = [];

                for (const file of files) {
                    const newDocument = await uploadDocument(sprinklerInspectionId, assuranceNumber, file);
                    if (newDocument) newDocumentList.push(newDocument);
                }

                setFiles([]);
                setDocument([...documentList, ...newDocumentList]);
            }

            message.success('Kontrolle erfolgreich aktualisiert');
            updateSprinklerInspection(updatedSprinklerInspection);

            if (closeAfterSubmit) navigate('/inspections');
        } catch (e) {
            message.error('Beim aktualisieren ist ein Fehler aufgetreten');
            console.error(e);
        } finally {
            setIsSaving(false);
        }
    };

    const {
        anlageart,
        assuranceNumber,
        nutzungsart,
        bewilligungsNr,
        errichterfirmaLoeschanlage,
        gebaudeAdresse,
        gebaeudeteile,
        grundbuchkreis,
        interneBemerkungen,
        kontrolldatum,
        kontrolltyp,
        subventionsNr,
        verwalter,
        schutzTyp,
        schutzBemerkungen,
        zusaetzlicheVerteiler,
        zeitaufwand,
        letzteKontrolle,
        letzteRevision,
        inUmbau,
        keineAnlage,
        fristUmbau,
        isPeriodischeKontrolleProzess,
    } = sprinklerInspection || {};

    const kontrollTypDisplay = SprinklerKontrolltypenDisplay.filter((x) => x.key === kontrolltyp)[0]?.displayValue;

    const isNachkontrolle = kontrolltyp === SprinklerKontrolltypen.nachkontrolle;
    const isKontrolltypPeriodischeKontrolle = kontrolltyp === SprinklerKontrolltypen.periodischeKontrolle;
    const isErstAbnahme = () => kontrolltyp === SprinklerKontrolltypen.erstabnahme;
    const isGebaeudeDataReadOnly = isNachkontrolle || isKontrolltypPeriodischeKontrolle;

    const isKeineAnlageReadOnly = currentKeineAnlage;

    const isAbnahme =
        kontrolltyp === SprinklerKontrolltypen.erstabnahme ||
        kontrolltyp === SprinklerKontrolltypen.teilabnahme ||
        kontrolltyp === SprinklerKontrolltypen.generalrevision;

    const isGeneralrevision = kontrolltyp === SprinklerKontrolltypen.generalrevision;

    return (
        <>
            <h1>Kontrollrapport {kontrollTypDisplay}</h1>
            <>
                <Form
                    style={{ paddingBottom: '10px' }}
                    {...twoColumnLayout}
                    labelAlign="left"
                    layout="horizontal"
                    form={form}
                    onFinish={onSubmit}
                >
                    {isNachkontrolle || isPeriodischeKontrolleProzess ? (
                        !isErstAbnahme() ? (
                            <Row>
                                <Col {...twoColumnLayout.labelCol}>Nutzungsart</Col>
                                <Col {...twoColumnLayout.wrapperCol}>
                                    <p>{assuranceNumber ? assuranceNumber : ''}</p>
                                </Col>
                            </Row>
                        ) : (
                            <Form.Item initialValue={nutzungsart} name="nutzungsart" label="Nutzungsart">
                                <Input />
                            </Form.Item>
                        )
                    ) : null}
                    {isGebaeudeDataReadOnly ? (
                        <Row>
                            <Col {...twoColumnLayout.labelCol}>Versicherungsnummer</Col>
                            <Col {...twoColumnLayout.wrapperCol}>
                                <p>
                                    {assuranceNumber ? assuranceNumber : ''}
                                    <span style={{ marginLeft: '10px' }}>
                                        {' '}
                                        {!isAbnahme && <MapButton inspection={sprinklerInspection} label="Lage" />}
                                    </span>
                                </p>
                            </Col>
                        </Row>
                    ) : (
                        <Form.Item
                            initialValue={assuranceNumber}
                            name="assuranceNumber"
                            label="Versicherungsnummer"
                            rules={[
                                { required: !isGebaeudeDataReadOnly, message: 'Eingabe erforderlich' },
                                () => ({
                                    validator(_, value) {
                                        if (value && value.length <= 15) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error('Bitte geben Sie eine gültige Versicherungs-Nr. ein.')
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    )}
                    {isGebaeudeDataReadOnly ? (
                        <Row>
                            <Col {...twoColumnLayout.labelCol}>Verwalter</Col>
                            <Col {...twoColumnLayout.wrapperCol}>
                                <p>{verwalter ? verwalter : ''}</p>
                            </Col>
                        </Row>
                    ) : (
                        <Form.Item
                            initialValue={verwalter}
                            name="verwalter"
                            label="Verwalter"
                            rules={[
                                { required: !isGebaeudeDataReadOnly, message: 'Eingabe erforderlich' },
                                () => ({
                                    validator(_, value) {
                                        if (value && value.length <= 250) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error('Der Verwalter hat zu viele Zeichen (max. 250)')
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    )}
                    {isGebaeudeDataReadOnly ? (
                        <Row>
                            <Col {...twoColumnLayout.labelCol}>Standortadresse</Col>
                            <Col {...twoColumnLayout.wrapperCol}>
                                <p>{gebaudeAdresse ? gebaudeAdresse : ''}</p>
                            </Col>
                        </Row>
                    ) : (
                        <Form.Item
                            initialValue={gebaudeAdresse}
                            name="gebaudeAdresse"
                            label="Standortadresse"
                            rules={[
                                { required: !isGebaeudeDataReadOnly, message: 'Eingabe erforderlich' },
                                () => ({
                                    validator(_, value) {
                                        if (value && value.length <= 250) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error('Die Standortadresse hat zu viele Zeichen (max. 255)')
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    )}
                    {!isPeriodischeKontrolleProzess &&
                        !isErstAbnahme() &&
                        (isNachkontrolle ? (
                            <Row>
                                <Col {...twoColumnLayout.labelCol}>betroffene Gebäudeteile</Col>
                                <Col {...twoColumnLayout.wrapperCol}>
                                    <p>{gebaeudeteile ? gebaeudeteile : ''}</p>
                                </Col>
                            </Row>
                        ) : (
                            <Form.Item
                                initialValue={gebaeudeteile}
                                name="gebaeudeteile"
                                label="betroffene Gebäudeteile"
                                rules={[
                                    {
                                        required: !isNachkontrolle && !isGeneralrevision,
                                        message: 'Eingabe erforderlich',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        ))}

                    {isGebaeudeDataReadOnly && (
                        <Row>
                            <Col {...twoColumnLayout.labelCol}>Gemeinde</Col>
                            <Col {...twoColumnLayout.wrapperCol}>
                                <p>{grundbuchkreis ? grundbuchkreis : ''}</p>
                            </Col>
                        </Row>
                    )}

                    <Divider />
                    <Form.Item
                        rules={[{ required: true, message: 'Eingabe erforderlich' }]}
                        initialValue={kontrolldatum && moment(kontrolldatum)}
                        name="kontrolldatum"
                        label="Kontrolldatum"
                    >
                        <DatePicker
                            disabledDate={(current) => current && current > moment().endOf('day')}
                            format={gvsgDateFormat}
                        />
                    </Form.Item>
                    {isPeriodischeKontrolleProzess && (
                        <Form.Item
                            initialValue={letzteKontrolle && moment(letzteKontrolle)}
                            name="letzteKontrolle"
                            label="Letzte Kontrolle"
                        >
                            <DatePicker
                                disabled={isKeineAnlageReadOnly}
                                disabledDate={(current) => current && current > moment().endOf('day')}
                                format={gvsgDateFormat}
                            />
                        </Form.Item>
                    )}
                    {isPeriodischeKontrolleProzess && (
                        <Form.Item
                            initialValue={letzteRevision && moment(letzteRevision)}
                            name="letzteRevision"
                            label="Letzte Revision durch Sprinklerfirma"
                        >
                            <DatePicker
                                disabled={isKeineAnlageReadOnly}
                                disabledDate={(current) => current && current > moment().endOf('day')}
                                format={gvsgDateFormat}
                            />
                        </Form.Item>
                    )}
                    {isPeriodischeKontrolleProzess && isKontrolltypPeriodischeKontrolle && (
                        <Form.Item label="Objekt in Umbau" initialValue={inUmbau} name="inUmbau">
                            <Radio.Group onChange={(e) => setCurrentInUmbau(e.target.value)}>
                                <Radio value={true}>Ja</Radio>
                                <Radio value={false}>Nein</Radio>
                            </Radio.Group>
                        </Form.Item>
                    )}
                    {isPeriodischeKontrolleProzess && isKontrolltypPeriodischeKontrolle && (
                        <Form.Item
                            rules={[{ required: currentInUmbau, message: 'Eingabe erforderlich' }]}
                            initialValue={fristUmbau && moment(fristUmbau)}
                            name="fristUmbau"
                            label="Frist Umbau"
                        >
                            <DatePicker
                                disabled={!currentInUmbau}
                                disabledDate={(current) => current && current <= moment().endOf('day')}
                                format={gvsgDateFormat}
                            />
                        </Form.Item>
                    )}
                    {isPeriodischeKontrolleProzess && isKontrolltypPeriodischeKontrolle && (
                        <Form.Item label="Anlage vorhanden" initialValue={keineAnlage} name="keineAnlage">
                            <Radio.Group onChange={(e) => setCurrentKeineAnlage(e.target.value)}>
                                <Radio value={false}>Ja</Radio>
                                <Radio value={true}>Nein</Radio>
                            </Radio.Group>
                        </Form.Item>
                    )}
                    <Form.Item name="zeitaufwand" label="Zeitaufwand in std." initialValue={zeitaufwand}>
                        <InputNumber decimalSeparator="." min={0} max={100} precision={2} />
                    </Form.Item>
                    {!isPeriodischeKontrolleProzess &&
                        (isNachkontrolle ? (
                            <Row>
                                <Col {...twoColumnLayout.labelCol}>Errichterfirma der Löschanlage</Col>
                                <Col {...twoColumnLayout.wrapperCol}>
                                    <p>{errichterfirmaLoeschanlage ? errichterfirmaLoeschanlage : ''}</p>
                                </Col>
                            </Row>
                        ) : (
                            <Form.Item
                                initialValue={errichterfirmaLoeschanlage}
                                name="errichterfirmaLoeschanlage"
                                label="Errichterfirma der Löschanlage"
                                rules={[{ required: !isNachkontrolle, message: 'Eingabe erforderlich' }]}
                            >
                                <Input />
                            </Form.Item>
                        ))}
                    {!isPeriodischeKontrolleProzess && (
                        <AnlageArtInput isReadonly={isNachkontrolle} anlageArtValue={anlageart} />
                    )}
                    <Divider />
                    <Form.Item label="Schutzumfang" initialValue={schutzTyp} name="schutzTyp">
                        <Radio.Group disabled={isKeineAnlageReadOnly}>
                            <Radio value={SchutzTyp.vollschutz}>Vollschutz</Radio>
                            <Radio value={SchutzTyp.teilschutz}>Teilschutz</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name="schutzBemerkungen"
                        rules={[{ required: !isGeneralrevision, message: 'Eingabe erforderlich' }]}
                        initialValue={schutzBemerkungen}
                        label="geschützte Gebäudebereiche"
                    >
                        <Input.TextArea rows={4} disabled={isKeineAnlageReadOnly} />
                    </Form.Item>
                    {!isNachkontrolle && (
                        <div>
                            <Divider />
                            <PflichtigInputs inspection={sprinklerInspection} disabled={isKeineAnlageReadOnly} />
                        </div>
                    )}
                    {!isPeriodischeKontrolleProzess && (
                        <Form.Item name="bewilligungsNr" initialValue={bewilligungsNr} label="Bewilligungs-Nr.">
                            <Input />
                        </Form.Item>
                    )}
                    {!isPeriodischeKontrolleProzess && (
                        <Form.Item name="subventionsNr" initialValue={subventionsNr} label="Subventions-Nr.">
                            <Input />
                        </Form.Item>
                    )}
                    <Divider />
                    <MaengelFormInputs inspection={sprinklerInspection} disabled={isKeineAnlageReadOnly} />
                    <Form.Item
                        initialValue={interneBemerkungen}
                        name="interneBemerkungen"
                        label="Allgemeine Bemerkungen zum Objekt (INTERN)"
                    >
                        <Input.TextArea rows={4} />
                    </Form.Item>
                    <Form.Item
                        initialValue={zusaetzlicheVerteiler}
                        name="zusaetzlicheVerteiler"
                        label="zus. Verteiler (System)"
                    >
                        <Input.TextArea rows={4} disabled={isKeineAnlageReadOnly} />
                    </Form.Item>
                    <Row style={{ marginBottom: '10px' }} hidden={isKeineAnlageReadOnly}>
                        <Col {...oneColumnLayout.wrapperCol}>
                            <UploadDocumentComponent setFiles={setFiles} files={files ?? []} />
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '10px' }} hidden={isKeineAnlageReadOnly}>
                        <Col {...twoColumnLayout.labelCol}>Hochgeladene Anhänge</Col>
                        <Col {...twoColumnLayout.wrapperCol}>
                            <DocumentList
                                onDelete={(document) => {
                                    const updatedDocumentList = documentList.filter((d) => d.id !== document.id);
                                    setDocument(updatedDocumentList);
                                }}
                                documents={documentList}
                            />
                        </Col>
                    </Row>
                    <Form.Item {...oneColumnLayout}>
                        <Row>
                            <Col>
                                <Button
                                    loading={isSaving}
                                    htmlType="button"
                                    type="primary"
                                    onClick={(e) => {
                                        setCloseAfterSubmit(false);
                                        form.submit();
                                    }}
                                >
                                    Speichern
                                </Button>
                            </Col>
                            <Col style={{ marginLeft: '10px' }}>
                                <Button
                                    loading={isSaving}
                                    htmlType="button"
                                    onClick={(e) => {
                                        setCloseAfterSubmit(true);
                                        setInspectionStateAfterSubmit(SprinklerInspectionStatus.completed);
                                        form.submit();
                                    }}
                                    type="primary"
                                >
                                    Senden
                                </Button>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            </>
        </>
    );
};
