import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AppLayout } from './AppLayout';
import { Inspections } from './pages/InspectionsTable';
import { NewInspection } from './pages/inspection';
import { FormSelector } from './pages/inspection/edit/FormSelector';

export const AppRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<AppLayout />}>
                    <Route path="inspections" element={<Inspections />} />
                    <Route path="inspections/:inspectionId" element={<FormSelector />} />
                    <Route path="newInspection" element={<NewInspection />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};
