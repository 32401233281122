import axios, { AxiosInstance } from 'axios';
import { getCurrentUser } from '../common/token';
import { getEnvironment } from '../config/enviornment';

const { api } = getEnvironment();
const baseURL = `${api}/v1/`;
export const ApiInstance: AxiosInstance = axios.create({
    baseURL,
    headers: {
        'Access-Control-Allow-Origin': '*',
    },
});

export const AnonymousApiInstance: AxiosInstance = axios.create({
    baseURL,
    headers: {
        'Access-Control-Allow-Origin': '*',
    },
});

function authHeader() {
    const { token } = getCurrentUser();
    return `Bearer ${token}`;
}

ApiInstance.interceptors.request.use(
    (config) => {
        if (!config || !config.headers) throw new Error('config.headers cannob be null');
        config.headers.Authorization = authHeader();
        return config;
    },
    (error) => Promise.reject(error)
);

ApiInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error && error.response && error.response.status) {
            if (error.response.status === 401 || error.response.status === 403) {
                console.error('Ihre Sitzung ist abgelaufen. Bitte loggen Sie sich aus und erneut ein');
            }
        }
        return Promise.reject(error);
    }
);
