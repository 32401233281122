export interface User {
    id?: string;
    firstName?: string;
    lastName?: string;
    username?: string;
    password?: string;
    token?: string;
    inspectorType?: KontrolleurTyp;
}

export interface SprinklerInspection {
    bfsNumber?: string;
    geoportalGebaeudeNumber?: string;
    houseNumber?: string;
    street?: string;
    id: string;
    inspectorId?: string;
    assuranceNumber?: string;
    verwalter?: string;
    nutzungsart?: string; //Nutzungsart
    gebaudeAdresse: string; // Standortadresse Gebäude
    grundbuchkreis: string; // Grundbuchkreis
    kontrolldatum?: Date; // inspectiondate
    kontrolltyp?: SprinklerKontrolltypen;
    gebaeudeteile?: string;
    zeitaufwand?: number;
    errichterfirmaLoeschanlage?: string;
    anlageart?: AnlageArt;
    schutzTyp?: SchutzTyp;
    schutzBemerkungen?: string;
    pflichtig?: Pflichtig;
    pflichtigBemerkungen?: string;
    bewilligungsNr?: string;
    subventionsNr?: string;
    kontrollergebnis?: Kontrollergebnis;
    maengel?: string;
    fristbehebung?: string;
    bemerkungen?: string; // Spezielles / Bemerkungen
    interneBemerkungen?: string;
    zusaetzlicheVerteiler?: string;
    frist?: Date;
    nachkontrolleErforderlich?: boolean;
    status?: SprinklerInspectionStatus;
    letzteKontrolle?: Date;
    letzteRevision?: Date;
    inUmbau?: boolean;
    keineAnlage?: boolean;
    fristUmbau?: Date;
    isPeriodischeKontrolleProzess?: boolean;
}

export interface Document {
    id?: string;
    name?: string;
    uri?: string;
    createdOn?: Date;
    modifiedOn?: Date;
    sprinklerInspection?: string;
    documentSource?: DocumentSource;
    valeriaId?: string;
}

export enum SchutzTyp {
    teilschutz = 0,
    vollschutz = 1,
}

export enum KontrolleurTyp {
    Kontrolleur = 0,
    Aufseher = 1,
}
export enum SprinklerInspectionStatus {
    open = 0,
    started = 1,
    completed = 2,
    cancelled = 3,
    cancellationPending = 4,
}

export enum DocumentSource {
    Unknown = 0,
    InspectionUpload = 1,
    PortalPhotoUpload = 2,
}

export enum SafetyInspectionKind {
    periodical = 0,
    followUp = 1,
}

export enum SprinklerKontrolltypen {
    periodischeKontrolle = 1,
    erstabnahme = 2,
    generalrevision = 3,
    nachkontrolle = 4,
    teilabnahme = 7,
}

export enum AnlageArt {
    sprinkleranlage = 1,
    spruehflutanlage = 2,
    gasloeschanlage = 3,
    sauerstoffreduzierungsanlage = 4,
}

export enum Pflichtig {
    freiwilig = 0,
    pflichtig = 1,
    unsicher = 2,
}

export enum Kontrollergebnis {
    maengelfrei = 1,
    einschraenkungen = 2,
    bedingt = 3,
    mangelhaft = 4,
}

/***********************
 * Sprinklerkontrollen *
 **********************/
// Kontrolltypen Sprinkler

export const KontrollergebnisDisplay: ModelEnum[] = [
    { key: Kontrollergebnis.maengelfrei, displayValue: 'Die SPA ist funktionstüchtig und mängelfrei' },
    {
        key: Kontrollergebnis.einschraenkungen,
        displayValue: 'Die SPA ist mit Einschränkungen funktionstüchtig (s. Bemerkungen)',
    },
    {
        key: Kontrollergebnis.bedingt,
        displayValue: 'Die SPA ist bedingt funktionstüchtig und muss ergänzt werden (s. Mängel)',
    },
    {
        key: Kontrollergebnis.mangelhaft,
        displayValue: 'Die SPA ist mangelhaft und muss gemäss SES-Richtlinien ergänzt werden (s. Mängel)',
    },
];

export const PflichtigDisplay: ModelEnum[] = [
    { key: Pflichtig.freiwilig, displayValue: 'Freiwillige Anlage' },
    { key: Pflichtig.pflichtig, displayValue: ' Pflichtige Anlage' },
    { key: Pflichtig.unsicher, displayValue: 'unsicher' },
];

export const AnlageArtDisplay: ModelEnum[] = [
    { key: AnlageArt.sprinkleranlage, displayValue: 'Sprinkleranlage' },
    { key: AnlageArt.spruehflutanlage, displayValue: 'Sprühflutanlage' },
    { key: AnlageArt.gasloeschanlage, displayValue: 'Gaslöschanlage' },
];

export const InspectionKindDisplay: ModelEnum[] = [
    { key: SafetyInspectionKind.periodical, displayValue: 'Periodische Kontrolle' },
    { key: SafetyInspectionKind.followUp, displayValue: 'Nachkontrolle' },
];

export const SprinklerKontrolltypenDisplay: ModelEnum[] = [
    { key: SprinklerKontrolltypen.periodischeKontrolle, displayValue: 'Periodische Kontrolle' },
    { key: SprinklerKontrolltypen.erstabnahme, displayValue: 'Erstabnahme' },
    { key: SprinklerKontrolltypen.generalrevision, displayValue: 'Generalrevision' },
    { key: SprinklerKontrolltypen.nachkontrolle, displayValue: 'Nachkontrolle' },
    { key: SprinklerKontrolltypen.teilabnahme, displayValue: 'Teilabnahme' },
];

export enum Benutzertyp {
    administrator = 'SAB',
    gemeindekonstrolleur = 'GKB',
    regionalaufseher = 'REG',
    sprinklerkontrolleur = 'SPKKontrolleur',
    sprinkleraufseher = 'SPKAufseher',
}

export const InspectionStatusDisplay: ModelEnum[] = [
    { key: 0, displayValue: 'Offen' },
    { key: 1, displayValue: 'In Bearbeitung' },
    { key: 2, displayValue: 'Abgeschlossen' },
    { key: 3, displayValue: 'Abgesagt' },
    { key: 4, displayValue: 'Absage In Arbeit' },
];

export interface ModelEnum {
    key: number;
    displayValue: string;
}
