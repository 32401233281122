import React from 'react';
import { LoginScreen } from './loginScreen';
import { isLoggedIn } from '../../common/token';

export type PrivatePropsType = {
    children: React.ReactNode;
};

export const Private = ({ children }: PrivatePropsType) => {
    return <>{!isLoggedIn() ? <LoginScreen /> : children}</>;
};
