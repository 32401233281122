export const twoColumnLayout = {
    labelCol: {
        md: { span: 24, offset: 0 },
        lg: { span: 6, offset: 0 },
        xxl: { span: 4, offset: 0 },
    },
    wrapperCol: {
        md: { span: 24, offset: 0 },
        lg: { span: 18, offset: 0 },
        xxl: { span: 20, offset: 0 },
    },
};

export const oneColumnLayout = {
    wrapperCol: {
        md: {
            offset: 0,
        },
        lg: {
            offset: 6,
        },
        xxl: {
            offset: 4,
        },
    },
};
