import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Typography } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import logo from '../../assets/gvsg_logo.svg';
import { ApiInstance } from '../../api/apiInstance';
import { User } from '../../models';
import { useNavigate } from 'react-router-dom';
import { setCurrentUser } from '../../common/token';

type FormDataType = {
    username: string;
    password: string;
};

export const LoginScreen = () => {
    let navigate = useNavigate();

    const [loginFailed, setLoginFailed] = useState(false);
    const [loginPending, setLoginPending] = useState(false);

    const [form] = Form.useForm();

    const onFinish = async (values: FormDataType) => {
        const { username, password } = values;

        setLoginPending(true);
        try {
            const { data: currentUser } = await ApiInstance.post<User>(`users/authenticate`, { username, password });

            setCurrentUser(currentUser);
            navigate('inspections');
        } catch (e) {
            setLoginFailed(true);
        } finally {
            setLoginPending(false);
        }
    };

    return (
        <>
            <Form style={{ padding: '30px' }} form={form} onFinish={onFinish}>
                <Col>
                    <Row>
                        <Col>
                            <img style={{ width: '119px', height: '60px' }} alt="GVSG" src={logo} />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '10px' }}>
                        <Col lg={8} sm={14}>
                            <h2>Login</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} lg={8} sm={14}>
                            <Form.Item name="username">
                                <Input
                                    prefix={<UserOutlined />}
                                    placeholder="Benutzername"
                                    style={{ color: 'rgba(0,0,0,.25)' }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} lg={8} sm={14}>
                            <Form.Item name="password">
                                <Input
                                    prefix={<LockOutlined type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    type="password"
                                    placeholder="Passwort"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} lg={8} sm={14}>
                            <Button block type="primary" htmlType="submit" loading={loginPending}>
                                Login
                            </Button>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 10, height: 20 }}>
                        <Col>
                            {loginFailed ? <Typography.Text type="danger">Login fehlgeschlagen</Typography.Text> : null}
                        </Col>
                    </Row>
                </Col>
            </Form>
        </>
    );
};
