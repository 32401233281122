import React from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import { AnlageArt, AnlageArtDisplay } from '../../../models';

type AnlageArtInputProps = {
    isReadonly: boolean;
    anlageArtValue: AnlageArt | undefined;
};

const layout = {
    labelCol: {
        xs: { span: 12, offset: 0 },
        sm: { span: 4, offset: 0 },
        lg: { span: 4, offset: 0 },
    },
    wrapperCol: {
        xs: { span: 12, offset: 0 },
        sm: { span: 14, offset: 0 },
        lg: { span: 8, offset: 0 },
    },
};

export const AnlageArtInput = ({ isReadonly, anlageArtValue }: AnlageArtInputProps) => {
    return (
        <>
            {isReadonly ? (
                <Row>
                    <Col {...layout.labelCol}>Anlage-Art</Col>
                    <Col {...layout.wrapperCol}>
                        <p>{AnlageArtDisplay.filter((a) => a.key === anlageArtValue)[0]?.displayValue}</p>
                    </Col>
                </Row>
            ) : (
                <Form.Item
                    rules={[{ required: true, message: 'Eingabe erforderlich' }]}
                    name="anlageart"
                    initialValue={anlageArtValue}
                    label="Anlage-Art"
                >
                    <Select>
                        <Select.Option value={AnlageArt.sprinkleranlage}>Sprinkleranlage</Select.Option>
                        <Select.Option value={AnlageArt.spruehflutanlage}>Sprühflutanlage</Select.Option>
                        <Select.Option value={AnlageArt.gasloeschanlage}>Gaslöschanlage</Select.Option>
                        <Select.Option value={AnlageArt.sauerstoffreduzierungsanlage}>
                            Sauerstoffreduzierungsanlage
                        </Select.Option>
                    </Select>
                </Form.Item>
            )}
        </>
    );
};
