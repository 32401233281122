import React, { useState } from 'react';
import { Button, Divider, Form, Input, Radio, Select, DatePicker, InputNumber, Row, Col, message } from 'antd';
import { gvsgDateFormat } from '../../common/utils';
import {
    AnlageArt,
    Document,
    DocumentSource,
    SchutzTyp,
    SprinklerInspection,
    SprinklerInspectionStatus,
    SprinklerKontrolltypen,
} from '../../models';
import { getCurrentUser } from '../../common/token';
import { ApiInstance } from '../../api/apiInstance';
import { RcFile } from 'antd/lib/upload';
import { uploadDocument } from '../../api/documentService';
import { oneColumnLayout, twoColumnLayout } from './common/GridColumnSizes';
import { UploadDocumentComponent } from './common/UploadDocumentComponent';
import { MaengelFormInputs } from './common/MaengelFormInputs';
import { PflichtigInputs } from './common/PflichtigInputs';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { isFunctionDeclaration } from 'typescript';
import { DocumentList } from './edit/DocumentList';

export const NewInspection = () => {
    let navigate = useNavigate();
    const [selectedKontrolltyp, setSelectedKontrolltyp] = useState<SprinklerKontrolltypen>(
        SprinklerKontrolltypen.erstabnahme
    );

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [files, setFiles] = useState<RcFile[]>();
    const [inspectionId, setInspectionId] = useState<string>();
    const [closeAfterSubmit, setCloseAfterSubmit] = useState<boolean>(true);
    const [documents, setDocuments] = useState<Document[]>([]);

    const [inspectionStateAfterSubmit, setInspectionStateAfterSubmit] = useState<SprinklerInspectionStatus>(
        SprinklerInspectionStatus.started
    );
    const [form] = Form.useForm();

    const onSubmit = async (values: any) => {
        try {
            setIsSaving(true);

            const { id: inspectorId } = getCurrentUser();
            const modelToUpdate: SprinklerInspection = {
                ...values,
                inspectorId,
                status: inspectionStateAfterSubmit,
            };

            const { data: sprinklerInspection } = inspectionId
                ? await ApiInstance.put<SprinklerInspection>(`sprinklerInspections/${inspectionId}`, modelToUpdate)
                : await ApiInstance.post<SprinklerInspection>(`sprinklerInspections`, modelToUpdate);

            if (!inspectionId && sprinklerInspection.id) {
                setInspectionId(sprinklerInspection.id);
            }

            if (files && sprinklerInspection && sprinklerInspection.assuranceNumber) {
                const { id: sprinklerInspectionId, assuranceNumber } = sprinklerInspection;

                const newDocumentList: Document[] = [];

                for (const file of files) {
                    const newDocument = await uploadDocument(sprinklerInspectionId, assuranceNumber, file);
                    if (newDocument) newDocumentList.push(newDocument);
                }

                setFiles([]);
                setDocuments([...documents, ...newDocumentList]);
            }

            message.success('Kontrolle erfolgreich aktualisiert');

            if (closeAfterSubmit) navigate('/inspections');
        } catch (e) {
            message.error('Beim aktualisieren ist ein Fehler aufgetreten');
            console.error(e);
        } finally {
            setIsSaving(false);
        }
    };

    const isErstAbnahme = selectedKontrolltyp !== SprinklerKontrolltypen.erstabnahme;
    const isGeneralrevision = selectedKontrolltyp === SprinklerKontrolltypen.generalrevision;

    return (
        <>
            <h1>Kontrollrapport</h1>

            <Form
                labelAlign="left"
                style={{ paddingBottom: '10px' }}
                {...twoColumnLayout}
                layout="horizontal"
                form={form}
                onFinish={onSubmit}
            >
                <Form.Item
                    rules={[{ required: true, message: 'Eingabe erforderlich' }]}
                    name="kontrolltyp"
                    label="Kontrolltyp"
                >
                    <Select onChange={setSelectedKontrolltyp}>
                        <Select.Option value={SprinklerKontrolltypen.erstabnahme}>Erstabnahme</Select.Option>
                        <Select.Option value={SprinklerKontrolltypen.generalrevision}>Generalrevision</Select.Option>
                        <Select.Option value={SprinklerKontrolltypen.teilabnahme}>Teilabnahme</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    rules={[
                        { required: true, message: 'Eingabe erforderlich' },
                        () => ({
                            validator(_, value) {
                                if (value && value.length <= 15) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Bitte geben Sie eine gültige Versicherungs-Nr. ein.'));
                            },
                        }),
                    ]}
                    name="assuranceNumber"
                    label="Versicherungsnummer"
                >
                    <Input maxLength={15} />
                </Form.Item>
                <Form.Item
                    rules={[
                        { required: true, message: 'Eingabe erforderlich' },
                        () => ({
                            validator(_, value) {
                                if (value && value.length <= 250) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Der Verwalter hat zu viele Zeichen (max. 250)'));
                            },
                        }),
                    ]}
                    name="verwalter"
                    label="Verwalter"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    rules={[
                        { required: true, message: 'Eingabe erforderlich' },
                        () => ({
                            validator(_, value) {
                                if (value && value.length <= 250) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Die Standortadresse hat zu viele Zeichen (max. 255)'));
                            },
                        }),
                    ]}
                    name="gebaudeAdresse"
                    label="Standortadresse Gebäude"
                >
                    <Input />
                </Form.Item>

                {isErstAbnahme ? (
                    <Form.Item
                        rules={[{ required: !isGeneralrevision, message: 'Eingabe erforderlich' }]}
                        name="gebaeudeteile"
                        label="betroffene Gebäudeteile"
                    >
                        <Input />
                    </Form.Item>
                ) : null}

                <Divider />

                {/* darf nicht in der zukunft liegen */}
                <Form.Item
                    rules={[{ required: true, message: 'Eingabe erforderlich' }]}
                    name="kontrolldatum"
                    label="Kontrolldatum"
                >
                    <DatePicker
                        disabledDate={(current) => current && current > moment().endOf('day')}
                        format={gvsgDateFormat}
                    />
                </Form.Item>

                <Form.Item
                    name="zeitaufwand"
                    rules={[{ required: true, message: 'Eingabe erforderlich' }]}
                    label="Zeitaufwand in std."
                    initialValue={0}
                >
                    <InputNumber decimalSeparator="." min={0} max={100} precision={2} />
                </Form.Item>

                <Form.Item
                    rules={[{ required: true, message: 'Eingabe erforderlich' }]}
                    name="errichterfirmaLoeschanlage"
                    label="Errichterfirma der Löschanlage"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    rules={[{ required: true, message: 'Eingabe erforderlich' }]}
                    name="anlageart"
                    label="Anlage-Art"
                >
                    <Select>
                        <Select.Option value={AnlageArt.sprinkleranlage}>Sprinkleranlage</Select.Option>
                        <Select.Option value={AnlageArt.spruehflutanlage}>Sprühflutanlage</Select.Option>
                        <Select.Option value={AnlageArt.gasloeschanlage}>Gaslöschanlage</Select.Option>
                        <Select.Option value={AnlageArt.sauerstoffreduzierungsanlage}>
                            Sauerstoffreduzierungsanlage
                        </Select.Option>
                    </Select>
                </Form.Item>

                <Divider />

                <Form.Item label="Schutzumfang" initialValue={0} name="schutzTyp">
                    <Radio.Group value="0">
                        <Radio value={SchutzTyp.vollschutz}>Vollschutz</Radio>
                        <Radio value={SchutzTyp.teilschutz}>Teilschutz</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item name="schutzBemerkungen" label="geschützte Gebäudebereiche">
                    <Input.TextArea rows={4} />
                </Form.Item>

                <Divider />

                <PflichtigInputs />

                <Form.Item name="bewilligungsNr" label="Bewilligungs-Nr.">
                    <Input />
                </Form.Item>
                <Form.Item name="subventionsNr" label="Subventions-Nr.">
                    <Input />
                </Form.Item>
                <Divider />

                <MaengelFormInputs />

                <Form.Item name="interneBemerkungen" label="Allgemeine Bemerkungen zum Objekt (INTERN)">
                    <Input.TextArea rows={4} />
                </Form.Item>
                <Form.Item name="zusaetzlicheVerteiler" label="zus. Verteiler (System)">
                    <Input.TextArea rows={4} />
                </Form.Item>
                <Row style={{ marginBottom: '10px' }}>
                    <Col {...oneColumnLayout.wrapperCol}>
                        <UploadDocumentComponent setFiles={setFiles} files={files ?? []} />
                    </Col>
                </Row>

                <Row style={{ marginBottom: '10px' }} hidden={false}>
                    <Col {...twoColumnLayout.labelCol}>Hochgeladene Anhänge</Col>
                    <Col {...twoColumnLayout.wrapperCol}>
                        <DocumentList
                            onDelete={(document) => {
                                const updatedDocumentList = documents.filter((d) => d.id !== document.id);
                                setDocuments(updatedDocumentList);
                            }}
                            documents={documents}
                        />
                    </Col>
                </Row>

                <Form.Item {...oneColumnLayout}>
                    <Row>
                        <Col>
                            <Button
                                loading={isSaving}
                                htmlType="button"
                                type="primary"
                                onClick={(e) => {
                                    setCloseAfterSubmit(false);
                                    form.submit();
                                }}
                            >
                                Speichern
                            </Button>
                        </Col>
                        <Col style={{ marginLeft: '10px' }}>
                            <Button
                                loading={isSaving}
                                htmlType="button"
                                onClick={(e) => {
                                    setCloseAfterSubmit(true);
                                    setInspectionStateAfterSubmit(SprinklerInspectionStatus.completed);
                                    form.submit();
                                }}
                                type="primary"
                            >
                                Senden
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </>
    );
};
